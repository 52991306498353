import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccessService {

  constructor(private http: HttpClient) { }

  getRoles(param) {
    return this.http.post(environment.wmtService + "search_roles", param);
  }

  delete(user){
    return this.http.post(environment.wmtService + "delete_user", {user});
  }

  update(user){
    return this.http.post(environment.wmtService + "update_user", {user});
  }

  create(user){
    return this.http.post(environment.wmtService + "create_user", {user});
  }
}
