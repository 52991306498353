import { DisciplineData } from './discipline';

export class SiteData {
    siteID: number;
    siteName: string;
    disciplines: DisciplineData[];
}

export class site {
    siteID;
    siteName;
}